











































import Vue from 'vue';
import PopUp from '@/configurator/components/utils/PopUp.vue';
import Spinner from '@/configurator/components/utils/Spinner.vue';

import {getRequestData} from '@/common/utils/get-request-data';
import {API_BASE} from '@/common/utils/api-url';
import {getParentUrl} from '@/common/utils/helper';

export default Vue.extend({
  components: {
    PopUp,
    Spinner,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  async mounted() {
    this.isLoading = true;

    const configurator = await this.$sdkConnector.api;

    const requestData = await getRequestData(this.$sdkConnector, this.$store, (this as any).t.t('part-list.selected-config'));

    requestData.webTeaser = this.$store.state.uiState.initData.customConfig.general.partListDescription;

    const {image} = await configurator.preparePerspectiveImage();
    requestData.thumbnail = image;

    const currentConfiguration = await this.$sdkConnector.saveCurrentConfiguration();
    const configurationId = currentConfiguration.id;
    let shareUrl = getParentUrl(this.$store.state.coreData.parentUrl);
    if (shareUrl && configurationId) {
      const params = new URLSearchParams(shareUrl.search);
      params.append('configuratorId', configurationId);
      shareUrl.search = '';
      shareUrl = new URL(shareUrl.href + '?' + params.toString());
    } else {
      shareUrl = new URL(`${API_BASE}/configurator/?configuratorId=${configurationId}`);
    }

    const baseUrl = new URL(String(shareUrl));
    baseUrl.search = '';
    requestData.baseUrl = baseUrl.toString();

    const requestOptions = {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestData),
    };

    try {
      // @ts-ignore
      const response = await fetch(`${API_BASE}/api/v1/roomle/add-to-cart`, requestOptions);
      const data = await response.json();

      if (data.success) {
        window.parent.postMessage(
          {
            action: 'success add cart',
            result: data.result,
          },
          API_BASE,
        );
      } else {
        window.parent.postMessage(
          {
            action: 'error add cart',
            data: null,
          },
          API_BASE,
        );
      }
    } catch (e) {
      window.parent.postMessage(
        {
          action: 'error add cart',
          data: null,
        },
        API_BASE,
      );
    }

    this.isLoading = false;
  },

  props: {
    onClose: Function,
  },

  methods: {
    redirect() {
      window.parent.postMessage('redirect shop', API_BASE);
    },
  },

});
